import * as React from "react"
import Layout from "../components/layout"

import at from "../images/at.svg";

export default function Index() {
  return (
    <Layout>
      <h1>Policy</h1>
      <h2 id="shipping">Shipping Policy</h2>
      <h3>General</h3>
      <p>All maps are shipped through Canada Post from my home in Val Marie. Orders will generally be shipped within two business days.</p>
      <p style={{color: "red"}}><strong>As a sole proprietor, I may be absent for longer, especially for tradeshows and industrial conferences. My website will notify you of any shipping delays exceeding one week.</strong></p>
      <p>Because my <em>Alberta</em> is an oversize product longer than 1m, it is literally not worth shipping outside Canada and the United States. International customers, please contact me.</p>
      <h3>Sales tax</h3>
      <p><strong>Canada:</strong> GST and PST are charged depending on your location in Canada. Please accurately select your province in the checkout.</p>
      <p>These rates may change without notice, as each province has a different threshold before sales tax reporting begins. No retroactive charges will apply. If you live in a province with a small business sales tax exemption, order now!</p>
      <p><strong>United States:</strong> To my knowledge, printed maps are duty-free. If you get hit with import charges anyway, you will be responsible for paying them.</p>
      <h2 id="refund">Refund Policy</h2>
      <p>All maps are shipped in a sturdy cardboard tube, fully insured. I inspect and pack each one myself.</p>
      <p>The following minor defects are unavoidable: small handling marks and tiny bubbles under the lamination. The cut edge of the sheet may be shifted by up to 1/8".</p>
      <p>If you find more extensive damage not covered by previous, please contact alex<img src={at} alt=" at " className="at" />awmcphee.ca within 14 days of delivery so I can assess and refund you.</p>
      <h2 id="privacy">Privacy Policy</h2>
      <p>Your credit card information is handled by <strong>Stripe</strong>, a secure payment processor used by millions of businesses. I do not have access to it.</p>
      <p>I have no interest in selling your shipping or contact information to anybody else, and will never do so.</p>
      <p>I made this website myself. I am not smart enough to make it put any tracking stuff in your browser. There is none of that.</p>
      <p>As a professional cartographer, I sometimes publish maps of my customer base. All such visualizations will be limited to the postal/zip code level, preserving your anonymity.</p>
    </Layout>
  )
}